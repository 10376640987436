// src/utils/api.js
import axios from 'axios';

// Create an instance of axios
const api = axios.create({
  baseURL: `${process.env.REACT_APP_DEV_BACK_URL}`, // Replace with your backend URL
});

// Attach JWT token and refresh token to each request
api.interceptors.request.use(
  (config) => {
    // Get the JWT token and refresh token from localStorage
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    
    if (refreshToken) {
      config.headers['Refresh-Token'] = refreshToken;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post(`${process.env.REACT_APP_DEV_BACK_URL}/token/verify`, 
          { access_token: localStorage.getItem('access_token') },
          { headers: { 'Refresh-Token': refreshToken } }
        );

        if (response.data.tokens) {
          localStorage.setItem('access_token', response.data.tokens.AccessToken);
          localStorage.setItem('refresh_token', response.data.tokens.RefreshToken);
          
          // Retry the original request with the new token
          originalRequest.headers['Authorization'] = `Bearer ${response.data.tokens.AccessToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        // Logout user or redirect to login page
      }
    }

    return Promise.reject(error);
  }
);

export default api;